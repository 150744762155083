@import "solar/Tokens.scss";

.component {
  font-size: $sol-title-5-size;
  line-height: $sol-text-line-lead;

  @media (min-width: $sol-breakpoint-lg) {
    font-size: $sol-title-5-size-responsive;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
} // .component

.italic {
  font-style: italic;
}
